// **Custom Theming for Angular Material**
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@angular/material/theming';

//used to include the core styles from the Angular Material library
@include mat.core();

//assign values(color name) for variables
:root {
    --primarylight: rgb(193, 215, 240);
    --primarynormal: #016f9b;
    --primarydark: #005374;
    --primaryextradark: #013d55;
    --loadercolor: #016f9b;
    --sideNavColor: #1e282c;
    // --sideNavColor: #;
    --selectedMenuColor: #068eef;
}

:root {
    --accentlight: #f3923e;
    --accentnormal: #FF8517;
    --accentdark: #f37507;
    --accentextradark: #fc7906;
}

// Use following link for generating color palette (http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5)
// Step 1 : Select the color. 
// Step 2 : Click download button (downward arrow symbol). 
// Step 3 : Choose output format (ANGULAR JS 2 (MATERIAL)).
// Step 4 : Finally copy the code and use it here.

// $blue-palette: (
//   50 : #e6edf7,
//   100 : var(--primarylight),
//   200 : #96b6dd,
//   300 : #6b98cf,
//   400 : var(--primarynormal),
//   500 : #2c6cba,
//   600 : #2764b3,
//   700 : var(--primarydark),
//   800 : #1b4fa3,
//   900 : var(--primaryextradark),
//   A100 : #c5d6ff,
//   A200 : #92b2ff,
//   A400 : #5f8fff,
//   A700 : #467dff,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #ffffff,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #ffffff,
//   )
// );
$blue-palette: (
    50: rgb(234, 242, 250),
    100: var(--primarylight),
    200: #98bce7,
    300: #6fa1dd,
    400: var(--primarynormal),
    500: #2c6cba,
    600: rgb(34, 84, 144),
    700: var(--primarydark),
    800: rgb(15, 36, 62),
    900: var(--primaryextradark),
    A100: rgb(208, 222, 240),
    A200:#016f9b,
    A400:#005374,
    A700: #013d55,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);


// $orange-palette: (
//   50 : #fff0e3,
//   100 : var(--accentlight),
//   200 : #ffc28b,
//   300 : #ffaa5d,
//   400 : var(--accentnormal),
//   500 : #ff8517,
//   600 : #ff7d14,
//   700 : var(--accentdark),
//   800 : #ff680d,
//   900 : var(--accentextradark),
//   A100 : #ffffff,
//   A200 : #fff7f4,
//   A400 : #ffd1c1,
//   A700 : #ffbfa7,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #000000,
//     600 : #000000,
//     700 : #000000,
//     800 : #000000,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   )
// );

$orange-palette: (
    50: #f3923e,
    100:var(--accentlight),
    200: #f3923e,
    300: #f3923e,
    400: var(--accentnormal),
    500: #FF8517,
    600: #FF8517,
    700: var(--accentdark),
    800: #f37507,
    900: var(--accentextradark),
    A100: #ecb483,
    A200:#f37507,
    A400: #eb9a53,
    A700: #fc7906,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// $mat-white: (
//   50 : #ffffff,
//   100 : #ffffff,
//   200 : #ffffff,
//   300 : #ffffff,
//   400 : #ffffff,
//   500 : #ffffff,
//   600 : #515d61,
//   700 : #3a4246,
//   800 : #23282a,
//   900 : #0c0d0e,
//   A100 : #ffffff,
//   A200 : #ffffff,
//   A400 : #ffffff,
//   A700 : #ffffff,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #000000,
//     600 : #000000,
//     700 : #000000,
//     800 : #000000,
//     900 : #000000,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   )
// );

$mat-white: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: rgb(81, 93, 97),
    700: rgb(58, 66, 70),
    800: rgb(35, 40, 42),
    900: rgb(12, 13, 14),
    A100: white,
    A200:white,
    A400: white,
    A700: white,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Create custom color palette.
// Ref Link : https://v15.material.angular.io/guide/typography
$zenbasket-primary: mat.define-palette(mat.$blue-palette, A200, A400, A100);
$zenbasket-accent: mat.define-palette(mat.$orange-palette, A100, A200, A400);
$zenbasket-warn: mat.define-palette(mat.$red-palette);

// Create custom typography.
// Ref Link : https://v15.material.angular.io/guide/typography 
$zenbasket-typography: mat.define-typography-config($font-family: 'Montserrat',
        $body-1: mat.define-typography-level(14px, 18px, 600),
        $body-2: mat.define-typography-level(14px, 24px, 500),
        $caption: mat.define-typography-level(15px, 32px, 600),
        $headline-5: mat.define-typography-level(16px, 48px, 600),
        $headline-6: mat.define-typography-level(20px, 40px, 600),
    );

// Passing the created color palette and typography in mat.define-light-theme.
$zenbasket-theme: mat.define-light-theme((color: (primary: $zenbasket-primary,
                accent: $zenbasket-accent,
                warn: $zenbasket-warn ),
            typography: $zenbasket-typography,
        ));

// Apply cutomized theme in all components.
@include mat.all-component-themes($zenbasket-theme);
@include mat.typography-hierarchy($zenbasket-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($zenbasket-typography);

/* You can add global styles to this file, and also import other style files */
$primary: mat.define-palette($zenbasket-primary, 100);
$accent: mat.define-palette($zenbasket-accent, 100);
$warn: mat.define-palette($zenbasket-warn, 100);
$primary: map-get($zenbasket-theme, primary);
$accent: map-get($zenbasket-theme, accent);

body {
    margin: 0px !important;
    overflow-x: hidden !important;
    font-family: 'Montserrat', sans-serif;
}