// @import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "https://fonts.googleapis.com/css?family=Montserrat";
@import "./theme.scss";

// You can add global styles to this file, and also import other style files /
// global searchbax design
// .mat-list-item, .mat-table, .mat-chip, .mat-dialog, .mat-card {
//   font-family:'Montserrat', sans-serif !important;
// }
body {
    margin: 0px !important;
    overflow-x: hidden !important;
    font-family: 'Montserrat', sans-serif;
}

table {
    width: 100%;
}

.table-header {
    padding: 20px;
    font-size: 24px;
    // font-family: '', serif;
}

.arts-container {
    width: 395px !important;
    padding: 5px !important;
}

::ng-deep .loader-spinner .prong {
    width: 8px !important;
    left: calc(43% - 1px) !important;
    color: var(--primary-color) !important;
}

::ng-deep .loader-spinner {
    width: 50px !important;
    height: 50px !important;
    position: absolute !important;
    z-index: 100 !important;
    top: 0 !important;
    right: 50% !important;
}

.general-category-class {
    margin-left: 30px !important;
}

.table-border .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: white !important;
}

.global-search-box .mat-form-field-flex {
    width: 25vmax !important;
}

.global-search-box-wrapper .mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.global-search-box {

    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0px !important;
    }
}

.global-search-icon {
    margin-bottom: 4px;
    float: right;
    color: grey;
}

.global-search-box-wrapper {
    margin: 20px !important;
}

.global-search-box .mat-form-field-wrapper .mat-form-field-infix .mat-input-element {
    text-overflow: ellipsis;
}

.serverside-search-rightside-layout {
    margin-top: 5px !important;
    display: block !important;
}

.clientside-search-rightside-layout {
    margin-top: 5px !important;
    display: block !important;
}

// custom table global searchbax design
.custom-table .global-search-box-wrapper .serverside-search-rightside-layout {
    margin-top: 5px !important;
    display: block !important;
}

.custom-table .global-search-box-wrapper .clientside-search-rightside-layout {
    margin-top: 5px !important;
    display: block !important;
}

.customer-email-style {
    color: green !important;
    font-weight: bold !important;
}

.user-name-style {
    color: var(--primary-color) !important;
    font-weight: bold !important;
}

.main-name-style {
    color: #1c4150 !important;

    font-weight: bold !important;
}

.price-style {
    font-weight: bold !important;
}

.draftRedChip {
    background-color: #ed3838 !important;
}

.align-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.cursor-pointer {
    cursor: pointer;
}

//custom table
// .custom-table .dynamic-table-page-container {
//   box-shadow: none;
//   margin: 0px !important;
// }
// .custom-table .dynamic-table-page-container table {
//   border-spacing: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
//   background: white;
//   width: 100%;
// }
// .custom-table .dynamic-table-page-container table .mat-header-row {
//   // background-color: #ebebeb;
//   border: transparent;
// }
.custom-table .dynamic-table-page-container table .mat-header-cell {
    font-size: 15px;
    font-weight: bold;
    color: var(--primary-color);
}

.custom-table .dynamic-table-no-record-message {
    font: 18px none !important;
}

// dialog customisation
.backdropBackground {
    background-color: hsla(0, 0%, 92.2%, 0.95);
    opacity: 1;
}

.mat-mdc-dialog-container .mdc-dialog__title {
    margin: 0% !important;
    background-color: whitesmoke !important;
    color: var(--primary-color) !important;
    font-size: var(primary-heading-size) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0% !important;
    margin: 0% !important;
    min-width: 300px !important;
    height: auto;
    overflow: hidden !important;
    // font-family: Roboto, Arial, sans-serif;
}

.mat-dialog-actions,
.mat-dialog-content {
    padding: 12px !important;
    margin: 0px !important;
}

.mat-icon.dialog-close-icon {
    float: right !important;
    line-height: 1.5 !important;
    color: grey !important;
}

.image-upload-dialog-content.mat-dialog-content {
    min-width: 400px;
}

.header-card-subtitle {
    font-size: 15px;
    margin-top: 0px;
}

.header-card-title {
    margin: 0 0 5px;
}

.primary-button {
    color: var(--light-color) !important;
    background-color: var(--primary-button-color) !important;
    font-weight: bolder !important;
    // font-size: 17px;
}

.basic-button {
    color: var(--dark-color) !important;
    background-color: var(--light-color) !important;
    font-weight: bolder !important;
    font-size: 17px;
}

.disabled-button {
    color: var(--light-color) !important;
    background-color: var(--primary-button-color) !important;
    font-weight: bolder !important;
    // font-size: 17px;
    opacity: 50%;
}

.secondary-button {
    border-color: var(--primary-button-color) !important;
    color: var(--primary-button-color) !important;
    font-weight: bolder !important;
    // font-size: 17px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--primary-color) !important;
}

// .mat-pseudo-checkbox-checked,
// .mat-pseudo-checkbox-indeterminate,
// .mat-accent .mat-pseudo-checkbox-checked,
// .mat-accent .mat-pseudo-checkbox-indeterminate {
//   background-color: var(--primary-color) !important;
// }

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--primary-color) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(55, 75, 165, 0.459) !important;
}

:root {
    --primary-store-color: #005374;
    --secondary-store-color: #ff8517;
    --tertiary-color: #d92727;
    --delete-icon: rgb(240, 56, 50);
    --new-ribbon-color: #005374;
    --primary-button-store-color: #016f9b;
    --light-store-color: white;
    --secondary-font-store-color: #818181;
    --navbar-color: white;
    --primary-color: #005374;
    --primary-button-color: #016f9b;
    --secondary-color: #ff8517;
    --star-color: #ffd700;
    --grey-shadow-color: rgba(116, 74, 29, 0.6);
    --background-color: rgba(116, 74, 29);
    --light-color: white;
    --secondary-font-color: #818181;
    --dark-color: black;
    --font-size: 1rem;
    --star-color: #ffd700;
    --footer-background-color: #131a22;
    --primary-heading-size: 26px;
    --primary-heading-weight: 400;
    --primary-heading-color: #333333;
    --page-secondary-text-size: 13px;
    --page-secondary-text-color: #313131;
    --tertiary-font-color: whitesmoke;
    --page-body-text-size: 16px;
    --page-body-text-color: #313131;
    --dialog-header-text-size: 20px;
    --dialog-header-text-color: #313131;
    --dialog-body-text-size: 16px;
    --dialog-body-text-color: #313131;
    --primary-font-size: 25px;
    --secondary-font-size: 14px;
    --special-font-family: "Montserrat";
    // --header-font: 'Montserrat', sans-serif !important;
    // --head-font: 'Montserrat', sans-serif !important;
    --note-font-size: 12px;
    --note-font-color: grey;
    // --header-font-family: 'Montserrat', sans-serif !important;
    // --global-font:'Montserrat', sans-serif !important;
    // --special-font-family: 'Montserrat', sans-serif !important;
    // --button-font: 'Montserrat', sans-serif !important;
    --mobile-primary-color: white;
    --mobile-secondary-color: #744a1d;
    --mobile-primary-font-color: #313131;
    --mobile-secondary-font-color: rgb(121, 120, 120);
    --mobile-font-size: 1rem;
    --mobile-active-color: rgba(235, 232, 233, 0.58);
    --out-of-stock-color: orange;
}

.button-color {
    background-color: var(--primary-button-color);
    color: white;
    height: auto;
}

.show-cursor {
    cursor: pointer;
}

.page-container {
    margin: 20px !important;
}

.mat-list-item-content mat-icon {
    color: #727272;
}

.page-content-container {
    margin: 20px;
    background-color: #ffffff;
    border: 1px solid #dadada;
}

.header-section {
    padding: 20px 20px 0px 20px;
}

.page-heading {
    font-weight: 400;
    font-size: 1.5rem;
}

.mat-icon-color {
    color: gray;
}

// .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
//   background-color: var(--primary-color);
// }
.test {
    //  padding: 0% !important;
    background-color: #000000;
    padding: 1%;
}

.myapp-no-padding-dialog .mat-dialog-container {
    // padding: 0;
    background-color: #000000;
    opacity: 0.9;
}

.test1 {
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: red;
    }
}

#storefront {
    // pointer-events: none !important;
    font-family: var(--special-font-family);
}

#storefront .slider-arrow-container {
    pointer-events: all !important;
}

#storefront h1 {
    font-size: var(--primary-font-size);
}


#storefront h3 {
    font-size: var(--secondary-font-size);
}

#storefront mat-card {
    font-family: var(--special-font-family);
}

#storefront table {
    font-family: var(--special-font-family);
}

#storefront mat-checkbox {
    font-family: var(--special-font-family);
}

#storefront mat-expansion-panel-header {
    font-family: var(--special-font-family);
}

#storefront div {
    font-family: var(--special-font-family);
}

#storefront button {
    font-family: var(--special-font-family);
}

#storefront .attention-banner {
    position: sticky;
    top: 0px !important;
    width: 100%;
}

#storefront .retail-sticky-category {
    position: sticky;
    width: 100%;
}

#storefront .retail-category {
    position: sticky;
    width: 100%;
    top: 0 !important;
}

#storefront .apparel-sticky-category {
    position: sticky;
    width: 100%;
}

#storefront .sticky-category {
    position: sticky;
}

#storefront .quickorder {
    position: sticky;
    padding: 5px;
}

#storefront .container-how-we-work {
    margin: 22px;
}

#storefront .dynamic-empty-data {
    font-size: 20px;
    font-weight: 600;
    color: gray;
    margin: 10px;
}

#storefront div>.splitter {
    position: relative;
}

.custom-gray-color {
    color: #a0a0a0;
}

.custom-svg-icon {
    height: 24px;
    width: 24px;
}

.alertIcon {
    color: orange;
    cursor: pointer;
}

.custom-header-large {
    text-align: center;
    font-size: 34px;
    color: #212121;
}

.image-disabled-button {
    color: var(--light-color) !important;
    background-color: var(--primary-button-color) !important;
    font-weight: bolder !important;
    font-size: 17px;
    opacity: 50%;
    pointer-events: none !important;
    cursor: none !important;
}

.custom-header-large span {
    display: inline-block;
    opacity: 0;
    transform: translate(-150px, 0) scale(0.6);
    animation: leftRight 2s forwards;
}

@keyframes leftRight {
    40% {
        transform: translate(50px, 0) scale(0.7);
        opacity: 1;
        color: #636363;
    }

    60% {
        color: #f72048;
    }

    80% {
        transform: translate(0) scale(2);
        opacity: 0;
    }

    100% {
        transform: translate(0) scale(1);
        opacity: 1;
    }
}

.sticky-category {
    position: sticky;
}

.photo-alt {
    fill: rgba(168, 168, 168);
    height: auto;
    width: 250px;
    cursor: pointer;
}

.no-events {
    pointer-events: none;
    cursor: not-allowed;
}

.restore-events {
    cursor: context-menu;
}

mat-icon {
    color: #727272;
}

.customer-name {
    font-weight: bold;
    // padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    color: var(--primary-color);
}

.email {
    // font-weight: bold;
    padding-bottom: 10px;
    font-size: 14px;
    color: var(--secondary-color);
}

.phone {
    font-size: 14px;
    // padding-bottom: 10px;
    // font-weight: bold;
    color: #016f9b;
}

.order-id {
    font-weight: bold;
    font-size: 18px;
    color: gray;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // margin-top: -21px;
}

.date-time {
    font-weight: bold;
    // padding-bottom: 10px;
    font-size: 14px;
    color: #016f9b;
}

.price {
    font-weight: bold;
    font-size: 21px;
}

.order-status {
    // padding-bottom: 10px;
    // padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
}

.payment-status {
    // margin-top: -19px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    // padding-top: 10px;
}

.authorized {
    color: rgb(33, 211, 33);
}

.paid {
    color: green;
}

.unpaid {
    color: orange;
}

.cancelled {
    color: red;
}

.refunded {
    color: rgb(0, 110, 255);
}

.partially-refunded {
    color: rgb(0, 229, 255);
}

.new {
    color: green;
}

.processing {
    color: orange;
}

.packed {
    color: blue;
}

.shipped {
    color: #016f9b;
}

.delivered {
    color: green;
}

.picked-up {
    color: #016f9b;
}

.returned {
    color: crimson;
}

.delivery {
    color: limegreen;
}

.pickup {
    color: darkorange;
}

.digitalProduct {
    color: var(--primary-color);
}

.shipping-type {
    font-weight: bold;
    font-size: 16px;
}

.zenbasket-order {
    font-size: 14px;
    color: #016f9b;
    font-weight: bold;
}

.order-viewed {
    color: rgb(19, 172, 19) !important;
}

.order-not-viewed {
    color: rgb(224, 0, 0) !important;
}

.inventoryRedChip {
    background-color: #ed3838 !important;
}

.requiredChip {
    background-color: green !important;
}

.pendingChip {
    background-color: orange !important;
}

.notRequiredChip {
    background-color: #d86565 !important;
}

.inActiveChip {
    background-color: rgb(224, 0, 0) !important;
}

.notAllowedChip {
    background-color: #b99329 !important;
}

.allowedChip {
    background-color: rgb(158, 52, 117) !important;
}

.notAllowed {
    color: rgb(158, 52, 117) !important;
    font-weight: bold;
}

.allowed {
    color: #b99329 !important;
    font-weight: bold;
}

.disapprovedChip {
    background-color: #ffd700 !important;
}

.inventoryBlueChip {
    background-color: blue !important;
}

.wholeSaleChip {
    background-color: purple !important;
}

.new-border {
    border-left: 5px solid green;
}

.processing-border {
    border-left: 5px solid orange;
}

.packed-border {
    border-left: 5px solid blue;
}

.shipped-border {
    border-left: 5px solid #016f9b;
}

.delivered-border {
    border-left: 5px solid green;
}

.picked-up-border {
    border-left: 5px solid #016f9b;
}

.returned-border {
    border-left: 5px solid crimson;
}

.cancelled-border {
    border-left: 5px solid red;
}

.thresholdLevelRedChip {
    background-color: #ed3838 !important;
}

.thresholdLevelBlueChip {
    background-color: blue !important;
}

.isCategoryMapped {
    background-color: #00cccd !important;
}

.isProductMapped {
    background-color: #ff8517 !important;
}

.isCategoryMappedBorder {
    border-left: 5px solid #00cccd;
}

.isProductMappedBorder {
    border-left: 5px solid #ff8517;
}

ngx-material-timepicker {
    z-index: 2000 !important;
}

.timepicker__header {
    background-color: var(--primary-color) !important;
}

.timepicker-button {
    color: var(--primary-color) !important;
}

.clock-face__number>span.active {
    background-color: var(--primary-color) !important;
}

.greenChip {
    background-color: green !important;
}

.orangeChip {
    background-color: #ff8517 !important;
}

.orangeBorder {
    border-left: 5px solid orangered !important;
}

.pendingBorder {
    border-left: 5px solid orange !important;
}

.greenBorder {
    border-left: 5px solid green !important;
}

.purpleBorder {
    border-left: 5px solid purple !important;
}

.redBorder {
    border-left: 5px solid red !important;
}

.blueBorder {
    border-left: 5px solid blue !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb {
    // padding-top: 10px;
    background: #9fa1a1;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(126, 127, 128);
}

.card {
    // box-shadow: 2px 4px 8px 1px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    // width: 40%;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    font-family: "Montserrat", sans-serif !important;
    border-radius: 5px;
    margin-top: 6px;
    background-color: white;
}

.message-block .mat-error-message {
    display: none;
}

.message-block .mat-error-message:first-child {
    display: block;
}

.mat-drawer-inner-container {
    overflow-x: hidden !important;
}

.lavanderBorder {
    border-left: 5px solid #8e67ee !important;
}

.pinkBorder {
    border-left: 5px solid #f44336bf !important;
}

.customerChip {
    background-color: #8e67ee !important;
}

.wholesaleCustomerChip {
    background-color: #f44336bf !important;
}

img {
    image-rendering: -moz-crisp-edges; //Firefox
    image-rendering: -o-crisp-edges; //Opera
    image-rendering: -webkit-optimize-contrast; //Webkit (non-standard naming)
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; //IE (non-standard property)
}

.custom-retail-ribbon1 {
    border-bottom: 50px solid transparent;
    position: absolute;
    left: 0px;
    top: 0px;
}

.custom-retail-ribbon-text1 {
    font-size: 14px;
    top: 10px;
    left: -46px;
    position: absolute;
    transform: rotate(-45deg);
}

.custom-retail-ribbon2 {
    border-bottom: 50px solid transparent;
    position: absolute;
    right: 0px;
    top: 0px;
}

.custom-retail-ribbon-text2 {
    font-size: var(--secondary-font-size);
    top: 10px;
    right: -47px;
    position: absolute;
    transform: rotate(45deg);
}

.custom-retail-ribbon-text3 {
    font-size: var(--secondary-font-size);
    bottom: 12px;
    left: -47px;
    position: absolute;
    transform: rotate(45deg);
}


.custom-retail-ribbon3 {
    border-top: 50px solid transparent;
    position: absolute;
    bottom: 0px;
    left: 0px;
    //  bottom:55px;
    //  top: -12px;
}

.custom-retail-ribbon4 {
    border-top: 50px solid transparent;
    position: absolute;
    bottom: 0px;
    right: 0px;
    //  bottom: 55px;
}

.custom-retail-ribbon-text4 {
    font-size: var(--secondary-font-size);
    bottom: 11px;
    right: -47px;
    position: absolute;
    transform: rotate(-45deg);
}

.design2-ribbon1 {
    height: 30px;
    width: 70px;
    position: relative;
}

.design2-ribbon-text1 {
    font-size: medium;
    text-align: center;
    line-height: 29px;
}

.design2-ribbon2 {
    height: 30px;
    width: 70px;
    position: relative;
    left: 249px;
}

.design2-ribbon-text2 {
    font-size: medium;
    text-align: center;
    line-height: 29px;
}

.design2-ribbon3 {
    height: 30px;
    width: 70px;
    position: absolute;
    //  top:-34px;
    left: 0px;
}

.design2-ribbon-text3 {
    font-size: medium;
    text-align: center;
    line-height: 29px;
}

.design2-ribbon4 {
    height: 30px;
    width: 70px;
    position: absolute;
    //  top: -35px;
    //  left: 249px;
    right: 0px;
}

.design2-ribbon-text4 {
    font-size: medium;
    text-align: center;
    line-height: 29px;
}

.oval-ribbon1 {
    height: 30px;
    width: 70px;
    position: relative;
    border-radius: 14px;
}

.oval-ribbon-text1 {
    font-size: medium;
    text-align: center;
    line-height: 29px;
}

.oval-ribbon2 {
    height: 30px;
    width: 70px;
    position: relative;
    left: 249px;
    border-radius: 14px;
}

.oval-ribbon-text2 {
    font-size: medium;
    text-align: center;
    line-height: 29px;
}

.oval-ribbon3 {
    height: 30px;
    width: 70px;
    position: absolute;
    //  top:-34px;
    left: 10px;
    border-radius: 14px;
}

.oval-ribbon-text3 {
    font-size: medium;
    text-align: center;
    line-height: 29px;
}

.oval-ribbon4 {
    height: 30px;
    width: 70px;
    position: absolute;
    //  top: -35px;
    //  left: 249px;
    right: 10px;
    border-radius: 14px;
}

.oval-ribbon-text4 {
    font-size: medium;
    text-align: center;
    line-height: 29px;
}

.tag-properties-tleft {
    padding: 8px 14px;
    position: absolute;
    left: -14px;
    top: 0px;
    border-top-left-radius: 7px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    width: max-content;
    transition: width 1s;

    &::before {
        display: block;
        content: "";
        height: 14px;
        width: 12px;
        position: absolute;
        background-color: #abb4b5;
        left: 0;
        bottom: -14px;
        border-bottom-left-radius: 14px;
    }
}

.tag-properties-tright {
    padding: 8px 14px;
    position: absolute;
    right: -14px;
    top: 0px;
    border-top-right-radius: 7px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
    width: max-content;
    transition: width 1s;

    &::before {
        display: block;
        content: "";
        height: 14px;
        width: 12px;
        position: absolute;
        background-color: #abb4b5;
        right: 0;
        bottom: -14px;
        border-bottom-right-radius: 14px;
    }
}

.tag-properties-bright {
    padding: 8px 14px;
    position: absolute;
    right: -12px;
    //  bottom: 18px;
    border-top-right-radius: 7px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
    width: max-content;
    transition: width 1s;
    bottom: 18px;

    &::before {
        display: block;
        content: "";
        height: 14px;
        width: 12px;
        position: absolute;
        background-color: #abb4b5;
        right: 0;
        bottom: -14px;
        border-bottom-right-radius: 14px;
    }
}

.tag-properties-bleft {
    padding: 8px 14px;
    position: absolute;
    left: -12px;
    // bottom: 18px;
    border-top-left-radius: 7px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
    width: max-content;
    transition: width 1s;
    bottom: 18px;

    &::before {
        display: block;
        content: "";
        height: 14px;
        width: 12px;
        position: absolute;
        background-color: #abb4b5;
        left: 0;
        bottom: -14px;
        border-bottom-left-radius: 14px;
    }
}

/*ribbon*/
.ribbon1 {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    cursor: pointer;
}

.ribbon1::before,
.ribbon1::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid var(--new-ribbon-color);
}

.ribbon1 span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: var(--new-ribbon-color);
    box-shadow: 0 5px 10px var(--grey-shadow-color);
    color: var(--light-color);
    text-align: center;
}

/* ribbon top left*/
.ribbon-top-left1 {
    top: -10px;
    left: -10px;
}

.ribbon-top-left1::before {
    top: 0;
    right: 30px;
}

.ribbon-top-left1::after {
    bottom: 30px;
    left: 0;
}

.ribbon-top-left1 span {
    right: -15px;
    top: 20px;
    transform: rotate(-45deg);
}

// second design
.ribbon-top-left2 {
    top: -10px;
    right: -10px;
}

.ribbon2 {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    cursor: pointer;
    z-index: 2;
}

.ribbon-top-left2::before {
    top: 131px;
    right: -1px;
}

.ribbon2::before,
.ribbon2::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid var(--new-ribbon-color);
}

.ribbon-top-left2 span {
    right: -67px;
    top: 47px;
    transform: rotate(45deg);
}

.ribbon2 span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: var(--new-ribbon-color);
    box-shadow: 0 5px 10px var(--grey-shadow-color);
    color: var(--light-color);
    text-align: center;
}

.ribbon-top-left2::after {
    bottom: 139px;
    left: 11px;
}

// third design

.ribbon3 {
    width: 152px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    cursor: pointer;
}

.ribbon-top-left3 {
    bottom: -10px;
    left: -9px;
}

.ribbon-top-left3::before {
    top: 140px;
    right: 16px;
}

.ribbon3::before,
.ribbon3::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid var(--new-ribbon-color);
}

.ribbon3 span {
    position: absolute;
    display: block;
    width: 218px;
    padding: 10px 0;
    background-color: var(--new-ribbon-color);
    box-shadow: 0 5px 10px var(--grey-shadow-color);
    color: var(--light-color);
    text-align: center;
}

.ribbon-top-left3 span {
    right: -10px;
    top: 75px;
    transform: rotate(45deg);
}

.ribbon-top-left3::after {
    bottom: 126px;
    left: 0;
}

// forth design

.ribbon-top-left4 {
    bottom: -11px;
    right: -14px;
}

.ribbon4 {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    cursor: pointer;
}

.ribbon-top-left4::before {
    top: 139px;
    right: 120px;
}

.ribbon4::before,
.ribbon4::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid var(--new-ribbon-color);
}

.ribbon4 span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: var(--new-ribbon-color);
    box-shadow: 0 5px 10px var(--grey-shadow-color);
    color: var(--light-color);
    text-align: center;
    /* text-transform: unset; */
}

.ribbon-top-left4 span {
    right: -71px;
    top: 57px;
    transform: rotate(310deg);
}

.ribbon-top-left4::after {
    bottom: 140px;
    left: 138px;
}

.wdp-ribbon1 {
    display: inline-block;
    padding: 2px 20px 2px 7px;
    position: absolute;
    left: 0px;
    line-height: 24px;
    height: 23px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    background-color: var(--new-ribbon-color);
    top: 0px;
}

.wdp-ribbon-two1:before {
    display: inline-block;
    content: "";
    position: absolute;
    right: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 14px 15px;
    border-right-color: transparent !important;
    border-left-color: var(--new-ribbon-color);
    border-top-color: var(--new-ribbon-color);
    border-bottom-color: var(--new-ribbon-color);

}

.wdp-ribbon2 {
    display: inline-block;
    padding: 2px 7px 2px 20px;
    position: absolute;
    right: 0px;
    line-height: 24px;
    height: 23px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    background-color: var(--new-ribbon-color);
    top: 0px;
}

.wdp-ribbon-two2:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 14px 15px;
    border-right-color: var(--new-ribbon-color);
    border-left-color: transparent !important;
    border-top-color: var(--new-ribbon-color);
    border-bottom-color: var(--new-ribbon-color);
}

.wdp-ribbon3 {
    display: inline-block;
    padding: 2px 20px 2px 7px;
    position: absolute;
    left: 0px;
    // bottom: 5px;
    line-height: 24px;
    height: 23px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    background-color: var(--new-ribbon-color);
    bottom: 0px;
}

.wdp-ribbon-two3:before {
    display: inline-block;
    content: "";
    position: absolute;
    right: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 14px 15px;
    border-right-color: transparent !important;
    border-top-color: var(--new-ribbon-color);
    border-bottom-color: var(--new-ribbon-color);
    border-left-color: var(--new-ribbon-color);
}

.wdp-ribbon4 {
    display: inline-block;
    padding: 2px 7px 2px 20px;
    position: absolute;
    right: 0px;
    // bottom: 5px;
    line-height: 24px;
    height: 23px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    background-color: var(--new-ribbon-color);
    bottom: 0px;
}

.wdp-ribbon-two4:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 14px 15px;
    border-right-color: var(--new-ribbon-color);
    border-left-color: transparent !important;
    border-top-color: var(--new-ribbon-color);
    border-bottom-color: var(--new-ribbon-color);
}

.returnProduct {
    color: green;
    font-weight: bolder;
    font-size: 15px;
}

.message-block .mat-error-message {
    display: none;
}

.message-block .mat-error-message:first-child {
    display: block;
}

.sort-menus {
    padding: 8px 20px;
    cursor: pointer;
}

.sort-menus:hover {
    background-color: #dcf7ff;

    .sorting-container {
        display: block;
    }
}

.sort-menu-options {
    position: absolute;
    width: 200px;
    height: auto;
    z-index: 100;
    padding: 20px 0px;
    display: none;
    top: 0;
    font-size: 15px;
    right: 50px;
    // line-height: 34px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .15), 0 12px 17px 2px rgba(0, 0, 0, .1), 0 5px 22px 4px rgba(0, 0, 0, .09);
}

.sort-menu-options:hover {
    .sorting-container {
        display: block;
    }
}

.selected-class {
    cursor: pointer;
    z-index: 105;
    border: 3px solid #30c5f5 !important;

    .section-name-display {
        display: block;
        background-color: #30c5f5;
    }
}

// .selected-class::after {
//   content: attr(content);
//   position: absolute;
//   top: 0;
//   padding: 5px;
//   background-color: #30c5f5;
//   color: white;
//   font-size: 12px;
//   font-weight: bold;
//   // z-index: 2;
// }

.selected-class:hover {
    .sorting-container {
        display: block;
    }
}

.to-select-class {
    cursor: pointer;
    z-index: 2;
    // border: 0px;
}

.to-select-class:hover {
    border: 2.5px solid #F59431;

    .sorting-container {
        display: block;
    }
}

.to-select-class:hover::after {
    content: attr(content);
    top: 0;
    background-color: #F59431;
    padding: 5px;
    color: white;
    font-size: 12px;
    position: absolute;
    font-weight: 700;
    // z-index: 1;
}

.section-name-display {
    position: absolute;
    top: 0px;
    font-size: 13px;
    left: 0px;
    background-color: #F59431;
    padding: 6px 12px;
    font-weight: 700;
    color: white;
    display: none;
    z-index: 2;
}

.hover-class {
    cursor: pointer;
    // z-index: 2;
    position: relative;
    // border: 0px;
}

.hover-class:not(.nohover, .nohover-unlayer):hover {
    border: 2.5px solid #F59431;

    .sorting-container {
        display: block;
    }

    .section-name-display {
        display: block;
    }

    .add-section-above {
        display: block;
    }

    .add-section-below {
        display: block;
    }
}


// .hover-class:hover::after {
//   top: 0;
//   background-color: #F59431;
//   padding: 5px;
//   color: white;
//   font-size: 12px;
//   position: absolute;
//   font-weight: 700;
//   // z-index: 1;
// }

.sorting-container {
    width: 39px;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
    display: none;
    right: 0px;
    background: white;
    // font-size: 40px;
    // top: 42%;
    position: absolute;
    z-index: 105;
}

.sort-icon {
    padding: 9px;
    color: #5d6161;
    font-size: 21px;
}

.sort-icon:hover {
    color: #30c5f5;
}


.nav-toolbar {
    position: fixed;
}

.text-overflow-hide {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.scheduledBlueChip {
    background-color: blue !important;
}

.draftRedChip {
    background-color: #ed3838 !important;
}

.purpleChip {
    background-color: purple !important;
}

.processingOrangeChip {
    background-color: orange !important;
}

.partiallyCompletedChip {
    background-color: #008060 !important;
}

.view-button-content mat-icon {
    color: var(--primary-color);
}

.view-button-content :hover {
    mat-icon {
        color: var(--secondary-color);
    }
}

.digitalProductChip {
    background-color: rgb(14 39 136) !important;
}

.digitalProductBorder {
    border-left: 5px solid rgb(14 39 136) !important;
}

.add-section-above {
    position: absolute;
    font-size: 13px;
    background-color: #F59431;
    padding: 6px 12px;
    font-weight: 700;
    color: white;
    display: none;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
}

.add-section-below {
    position: absolute;
    font-size: 13px;
    background-color: #F59431;
    padding: 6px 12px;
    font-weight: 700;
    color: white;
    display: none;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
}

// .mat-icon {
//   width: auto !important;
//   height: auto !important;
// }
.booster,
.booster-sub-text {
    font-size: 14px;
    align-items: center;
    justify-content: left !important;
    width: 105px;
    text-align: left;

    .material-icons-outlined {
        font-size: 20px;
    }
}

.booster {
    margin-bottom: 5px;
}

.green-text {
    color: green;
}

.red-text {
    color: red;
}

.priceOffer {
    color: var(--secondary-color);
    border: dotted var(--primarynormal);
    padding: 10px 3px;
    margin-top: 30px;
    position: relative;
    white-space: nowrap;
}

.shop-now {
    mat-icon {
        color: #f9f9f9 !important;
    }
}

.healthfirst-image2 {
    transform: translateY(173px) !important;
}

.aspect-ratio-hint {
    font-size: 12px;
    color: grey;

    span {
        font-style: italic;
        font-weight: 600;
    }
}

.section-error-class {
    border: 3px solid #f53030;
}

.section-error-class::before {
    content: "This section contains an error.";
    position: absolute;
    right: 50px;
    top: 0;
    z-index: 105;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background: red;
    padding: 5px 15px;
    border-radius: 0 0 5px 5px;
}

mat-form-field.mat-form-field-appearance-fill {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: rgba(255, 255, 255, 0.185) !important;
    }

    .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
        background-color: rgba(255, 255, 255, 0.185) !important;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: rgba(255, 255, 255, 0.185) !important;
    }

    .mat-mdc-form-field-error-wrapper {
        padding: 0px !important;
    }

    .mdc-text-field--filled {
        padding: 0px !important;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 44px !important;
        line-height: 30px !important;
        font-size: initial !important;
        font-weight: 400 !important;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding-top: 32px !important;
        padding-bottom: 4px !important;
    }

    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
        font-size: initial !important;
        font-weight: 400 !important;
        color: #000000 !important;
    }

    .mat-icon {
        padding: 30px 0px 0px 0px !important;
        box-sizing: content-box;
    }

    mat-datepicker-toggle {
        .mat-mdc-icon-button svg {
            vertical-align: text-bottom !important;
            margin-top: 12px !important;
            margin-left: -1px !important;
        }

        .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
            top: 14px !important;
            left: 5px !important;
            margin-top: 3px !important;
            right: 7px !important;
            bottom: -5px !important;
            position: absolute;
            pointer-events: none;
            border-radius: inherit;
            // border: 1px solid red !important;
        }

        .mat-ripple {
            width: 36px !important;
            height: 36px !important;
            position: absolute;
            top: 17px !important;
            left: 5px !important;
            // border: 1px solid red !important; 

        }

        .mat-mdc-icon-button .mat-mdc-focus-indicator {
            bottom: -6px !important;
            top: 16px !important;
        }

        .mat-mdc-icon-button .mat-mdc-button-touch-target {
            top: 62% !important;
        }
    }
}

.mat-mdc-option .mdc-list-item__primary-text {
    width: -webkit-fill-available;
}

.mdc-tooltip__surface {
    font-size: 12px !important;
    font-weight: 300 !important;
    white-space: pre-line !important;
    position: relative;
    top: 20px !important;
}

.legacy .mat-mdc-checkbox .mdc-checkbox {
    padding: 0px !important;
}



//Changing default Radio Button Style for Customization Page


$radio-button-height: 20px;
$radio-button-width: 20px;

mat-radio-button {
    .mdc-form-field {
        .mdc-radio {
            height: $radio-button-height !important;
            width: $radio-button-width !important;
            padding: 0px !important;

            .mat-mdc-radio-touch-target,
            .mdc-radio__native-control {
                height: inherit !important;
                width: inherit !important;
            }

            .mdc-radio__background {
                &::before {
                    top: 50% !important;
                    left: 50% !important;
                    width: $radio-button-width !important;
                    height: $radio-button-height !important;
                    transform: translate(-50%, -50%) !important;
                }
            }
        }
    }
}


//Mat Tab Old Design

$tab-header-height: 50px;

.zen-mat-tab-group .mat-mdc-tab-header {
    height: $tab-header-height;
    border-bottom: 1px solid #e7e7e7e7;
}

.zen-mat-tab-group .mat-mdc-tab-body {
    height: 400px;
}

.zen-mat-tab-group .mat-mdc-tab.mdc-tab {
    height: $tab-header-height !important;
    flex-grow: 0 !important;
    min-width: 160px;
    letter-spacing: 0;
}

.zen-mat-tab-group .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: var(--primary-color) !important;
}

.zen-mat-tab-group .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: var(--primary-color) !important;
}

// mat-option .mat-pseudo-checkbox-checked {
//   background-color: transparent !important;
// }

mat-form-field:has(mat-select) .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
    padding: 0px !important;

    .mat-mdc-form-field-focus-overlay {
        background-color: inherit !important;
    }
}

.mat-mdc-text-field-wrapper {
    padding: 0px 16px !important;
}

.button-with-icon {
    flex-direction: row-reverse;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
}

// Adjusting padding in mat error displayed with form field.
.mat-error {
    display: block !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 !important;
}

.mat-mdc-form-field-subscript-wrapper {
    font-weight: 400 !important;
    font-size: 75% !important;
    line-height: 1.125 !important;
    letter-spacing: normal !important;
}

.commonChip {
    background-color: #ff8517;
    padding: 4px;
    border-radius: 26px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    height: 20px;
}

.mdc-text-field--disabled {
    background-color: rgba(255, 255, 255, 0.185) !important;
}

.mat-mdc-paginator .mdc-notched-outline__trailing,
.mat-mdc-paginator .mdc-notched-outline__notch,
.mat-mdc-paginator .mdc-notched-outline__leading {
    border: none !important;
}

.mat-mdc-paginator .mat-mdc-select {
    border-bottom: 1px solid rgba(0, 0, 0, .42) !important;
}

.mdc-form-field>label {
    padding-left: 8px !important;
}

.mat-mdc-card {
    padding: 16px;
}

.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0px !important;
}

.mat-pseudo-checkbox {
    width: 16px !important;
    height: 16px !important;
}

.mat-mdc-option .mat-icon,
.mat-mdc-option .mat-pseudo-checkbox-full {
    margin-right: 8px !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    padding: 0px !important
}


.mat-mdc-form-field-hint {
    color: rgba(0, 0, 0, .6) !important;
}

mat-form-field {
    margin: .25em 0;
}

// radio margin class for custom-editor-component
.radio-margin {
    .mdc-radio {
        margin-right: 5px !important;
    }
}

// Mat toggle action icon display update.
.mdc-switch__icons {
    display: none !important;
}

// Mat slide toggle color before select.
.mat-mdc-slide-toggle {
    --mdc-switch-disabled-selected-handle-color: var(--primary-color) !important;
    --mdc-switch-disabled-unselected-handle-color: #fafafa !important;
    --mdc-switch-disabled-selected-track-color: rgba(55, 75, 165, 0.459) !important;
    --mdc-switch-disabled-unselected-track-color: #fafafa !important;
    --mdc-switch-unselected-focus-state-layer-color: #fafafa !important;
    --mdc-switch-unselected-pressed-state-layer-color: #fafafa !important;
    --mdc-switch-unselected-focus-track-color: rgba(0, 0, 0, 0.38) !important;
    --mdc-switch-unselected-hover-track-color: rgba(0, 0, 0, 0.38) !important;
    --mdc-switch-unselected-pressed-track-color: rgba(0, 0, 0, 0.38) !important;
    --mdc-switch-unselected-track-color: rgba(0, 0, 0, 0.38) !important;
    --mdc-switch-unselected-focus-handle-color: #fafafa !important;
    --mdc-switch-unselected-hover-handle-color: #fafafa !important;
    --mdc-switch-unselected-pressed-handle-color: #fafafa !important;
    // --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
    --mdc-switch-unselected-handle-color: #fafafa !important;
    --mdc-switch-selected-icon-color: rgba(0, 0, 0, 0.38) !important;
    // --mdc-switch-selected-handle-color: var(--mdc-theme-surface, #fff) !;

    --mdc-switch-disabled-selected-icon-color: rgba(0, 0, 0, 0.38) !important;
    --mdc-switch-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38) !important;
    --mdc-switch-unselected-icon-color: rgba(0, 0, 0, 0.38) !important;
}

// Mat slide toggle color after select.
.mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--primary-color) !important;
    --mdc-switch-selected-handle-color: var(--primary-color) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--primary-color) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--primary-color) !important;
    --mdc-switch-selected-focus-handle-color: var(--primary-color) !important;
    --mdc-switch-selected-hover-handle-color: var(--primary-color) !important;
    --mdc-switch-selected-pressed-handle-color: var(--primary-color) !important;
    --mdc-switch-selected-focus-track-color: rgba(55, 75, 165, 0.459) !important;
    --mdc-switch-selected-hover-track-color: rgba(55, 75, 165, 0.459) !important;
    --mdc-switch-selected-pressed-track-color: rgba(55, 75, 165, 0.459) !important;
    --mdc-switch-selected-track-color: rgba(55, 75, 165, 0.459) !important;
}

.admin-menu-list-item .mdc-list-item__primary-text {
    text-overflow: inherit !important;
    white-space: inherit !important;
    overflow: inherit !important;
}

.admin-menu-list-item .mdc-list-item__content {
    text-overflow: inherit !important;
    white-space: inherit !important;
    overflow: unset !important;
    align-self: center !important;
    flex: 1 !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
    padding-right: 0px !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
    padding: 0px !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
    place-content: center !important;
}

.mdc-button {
    letter-spacing: normal !important;
}

mat-checkbox {
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: var(--primary-color) !important;
        background-color: var(--primary-color) !important;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
        color: #fff !important;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
        border-color: var(--primary-color) !important;
        background-color: var(--primary-color) !important;
    }

    label {
        cursor: pointer;
        font-size: 16px !important;
        padding-left: 0px !important;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
        background-color: var(--primary-color) !important;
    }

    .mdc-checkbox .mdc-checkbox__native-control {
        top: 11px !important;
        left: 11px !important;
    }

    .mdc-checkbox {
        padding: 0px !important;
        height: 16px;
        width: 16px !important;
        max-width: 16px !important;
        margin-right: 10px !important;
    }

    .mat-mdc-checkbox-ripple,
    .mdc-checkbox__ripple {
        top: -10px !important;
        left: -10px !important;
        right: -10px !important;
        bottom: -10px !important;
    }

    .mdc-checkbox__background {
        width: 16px !important;
        height: 16px !important;
    }

    .mdc-checkbox .mdc-checkbox__background {

        top: 0 !important;
        left: 0 !important;
    }

    .mdc-checkbox .mdc-checkbox__native-control {
        width: 16px !important;
        height: 16px !important;
        top: 0 !important;
        left: 0 !important;
    }
}

//Tooltip
.mdc-tooltip__surface {
    letter-spacing: 0px !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    white-space: pre-line !important;
    text-align: center !important;

}

.mat-mdc-tooltip-panel-below.mat-mdc-tooltip-panel {
    margin-top: 0px !important
}

.mat-mdc-tooltip-panel-above.mat-mdc-tooltip-panel {
    // margin-top:0px !important;
    margin-bottom: 30px !important
}

.mat-step-label-selected {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.mat-mdc-checkbox label {
    padding-left: 0px !important;
    font-size: 16px !important;
}

.mdc-button .mdc-button__label {
    letter-spacing: 0px !important;
}

.mat-mdc-select-value {
    letter-spacing: 0px !important;
}

.mat-mdc-select-arrow-wrapper {
    height: 16px !important;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: none !important
}


.material-icons-outlined {
    vertical-align: middle !important;
}

.mat-expansion-panel-header {
    font-weight: 400 !important;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    border: none !important;
    font-size: 16px !important;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill):focus {
    outline: none !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: rgb(165, 165, 165) !important;
}

.mat-mdc-tab-header {
    border-bottom: 1px solid #e7e7e7e7 !important;
}

.mdc-tab {
    font-size: 18px !important;
    letter-spacing: 0px !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: var(--primary-color) !important;
}

.card-check-box .mdc-checkbox {
    margin-right: 0 !important;
}

.nonValueChip {
    background-color: orange !important;
}

.activeChip {
    background-color: green !important;
}

.inActiveChip {
    background-color: red !important;
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}

.common-page-container {
    padding: 20px !important;
}